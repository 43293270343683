import { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import useSystemNotification from "../../../api/signalr/useSystemNotification";

interface ProductRequestsRefreshProps {
    refresh: () => void;
}

const useProductRequestsRefresh = (props: ProductRequestsRefreshProps) => {
    const statusMessage = useSystemNotification();

    useEffect(() => {
        if (statusMessage == "FilteringByPhotoFinished") {
            props.refresh();
        } else if (statusMessage == "FilteringByLLMFinished") {
            props.refresh();
        }
    }, [statusMessage]);
};

export default useProductRequestsRefresh;
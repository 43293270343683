import { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { useAuth } from '../utils';
import { useSignalR } from './useSignalr';


const useSystemNotification = () => {
    const { statusMessage } = useSignalR();
    return statusMessage;
};

export default useSystemNotification;

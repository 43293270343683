import React, { createContext, useContext, useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { useAuth } from '../utils';

export type SystemNotificationText = "Idle" | "FilteringByPhotoStarted" | "FilteringByPhotoFinished" | "FilteringByLLMStarted" | "FilteringByLLMFinished";

interface SignalRContextType {
  connection: signalR.HubConnection | null;
  statusMessage: SystemNotificationText;
}

const SignalRContext = createContext<SignalRContextType>({ connection: null, statusMessage: 'Idle' });

export const useSignalR = () => useContext(SignalRContext);

export const SignalRProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
  const [statusMessage, setStatusMessage] = useState<SystemNotificationText>('Idle');
  const { accessToken } = useAuth();

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_BASE_URL}/systemNotificationHub`, {
        accessTokenFactory: () => accessToken
      })
      .withAutomaticReconnect([0, 2000, 4000, 6000, 30000])
      .build();

    newConnection.on('ReceiveStatusUpdate', (result: SystemNotificationText) => {
      setStatusMessage(result);
    });

    newConnection.start()
      .then(() => setConnection(newConnection))
      .catch(err => console.error('SignalR Connection Error: ', err));

    return () => {
      newConnection.stop();
    };
  }, [accessToken]);

  return (
    <SignalRContext.Provider value={{ connection, statusMessage }}>
      {children}
    </SignalRContext.Provider>
  );
};
import React, { useMemo, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Typography, Chip, Divider, styled, Tooltip, CircularProgress, IconButton, LinearProgress } from "@mui/material";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from "react-router-dom";
import { ProductAttributeNames, ProductOffer } from "../../api/models/product-offer";
import ProductOfferCard from "./ProductOfferCard";
import { useProductService } from "../../api/services/productSearchService";
import { RelativeDateDisplay } from "../Shared/RelativeDateDisplay";
import { generateStructuredData, getAttributeValue, getAttributeValueAsNumber } from "../../api/models/seo.json";
import { getCurrencySymbol, useAuth, useCurrencySymbol } from "../../api/utils";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useSystemNotification from "../../api/signalr/useSystemNotification";
import useProductRequestsRefresh from "./Hooks/useProductRequestsRefresh";
import { ProductDetailsStatus } from "./ProductDetailsStatus";

export function ProductDetailsDisplay() {
    const { slug } = useParams<{ slug: string }>();
    const { useGetProductRequestBySlug, useGetProductOffers, useProductRequestRefresh, useProductRequestView } = useProductService();
    const [{ data: request, loading: requestLoading, error: requestError }, getProductRequest] = useGetProductRequestBySlug(slug || '');
    const [{ data: productOffers, loading: offersLoading, error: offersError }, getProductOffers] = useGetProductOffers(slug || '');
    const [{ loading: refreshLoading, data: refreshedRequest }, refreshRequest] = useProductRequestRefresh(slug || '');
    const [{ }, incrementView] = useProductRequestView(slug || '');
    const currencySymbol = useCurrencySymbol(request?.productAttributes || []);

    const refetch = useCallback(() => {
        getProductRequest();
        getProductOffers();
    }, [getProductRequest, getProductOffers]);

    useProductRequestsRefresh({ refresh: refetch });


    const initialPrice = getAttributeValueAsNumber(request?.productAttributes || [], ProductAttributeNames.InitialUserRequestedProductPrice);
    // Calculate price difference
    const priceDifference = parseFloat(((request?.price || 0) - initialPrice).toFixed(2));
    const priceDifferencePercentage = ((priceDifference / initialPrice) * 100); // Keep it as a number

    const memoizedValues = useMemo(() => {
        if (!request || !productOffers) {
            return { metaDescription: '', pageTitle: '', imageUrl: '', structuredData: null };
        }
        const metaDescription = `Best price for ${request?.searchTerm}: $${request?.price}. Compare offers and find the best deal on TryScout.`;
        const pageTitle = `${request?.searchTerm} - Best Price and Offers | TryScout`;
        const imageUrl = getAttributeValue(request?.productAttributes, ProductAttributeNames.ImageUrl) || 'https://tryscout.com/default-product-image.jpg';
        const structuredData = generateStructuredData(request, productOffers);
        const bestOfferId = productOffers[0].id;

        return { metaDescription, pageTitle, imageUrl, structuredData, bestOfferId };
    }, [request, productOffers]);
    const { metaDescription, pageTitle, imageUrl, structuredData, bestOfferId } = memoizedValues;


    const handleRefresh = () => {
        refreshRequest();
    };


    useEffect(() => {
        if (refreshedRequest) {
            refetch();
        }
    }, [refreshedRequest]);

    if (requestLoading || offersLoading) {
        return <CircularProgress />;
    }

    if (requestError || offersError) {
        return <Typography color="error">Error loading product details</Typography>;
    }

    if (!request) {
        return <Typography color="error">Product request not found</Typography>;
    }

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:type" content="product" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:site_name" content="TryScout" />
                <meta property="og:image" content={imageUrl} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={metaDescription} />
                <meta name="twitter:site" content="@TryScout" />
                <meta name="twitter:image" content={imageUrl} />
                {structuredData && (
                    <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                )}
            </Helmet>
            <ScrollableContent>
                <Box sx={{ maxWidth: 800, margin: 'auto', padding: 4 }}>
                    <Box display="flex" alignItems="center" flexWrap="wrap" gap={2}>
                        <Typography
                            variant="h4"
                            component="h1"
                            fontWeight="bold"
                            sx={{
                                color: 'text.primary',
                            }}
                        >
                            {request.searchTerm}
                        </Typography>
                        <Tooltip title={refreshLoading ? "Refreshing..." : "Refresh product request"}>
                            <span>
                                {refreshLoading ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    <IconButton onClick={handleRefresh}>
                                        <RefreshIcon />
                                    </IconButton>
                                )}
                            </span>
                        </Tooltip>
                    </Box>


                    {initialPrice && initialPrice > 0 ? (
                        <Box display="flex" alignItems="center" my={2}>
                            <Typography
                                variant="h6"
                                color="text.secondary"
                                mr={1}
                            >
                                Initial Price {currencySymbol}{initialPrice}
                            </Typography>
                            <Box display="flex" alignItems="center">
                                <Typography
                                    variant="h6"
                                    color={priceDifference < 0 ? "success.main" : "error.main"}
                                    mr={1}
                                >
                                    {priceDifference < 0 ? (
                                        <>
                                            <ArrowDropDownIcon />
                                            {currencySymbol}{Math.abs(priceDifference)} ({Math.abs(priceDifferencePercentage).toFixed(2)}%)
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                    ) : <></>}

                    {request.price && (
                        <Box display="flex" alignItems="center" my={2}>
                            <Typography
                                variant="h5"
                                color="primary"
                                fontWeight="bold"
                                mr={1}
                            >
                                {currencySymbol}{request.price}
                            </Typography>
                            <Tooltip title="Best price from all offers">
                                <Chip
                                    icon={<LocalOfferIcon />}
                                    label="Best Price"
                                    color="success"
                                    size="small"
                                />
                            </Tooltip>
                        </Box>
                    )}

                    <ProductDetailsStatus />

                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }} mt={2}>
                        <Typography variant="subtitle2" color="text.secondary">
                            Searched on:
                        </Typography>
                        <RelativeDateDisplay
                            date={request.updateDate}
                            typographyProps={{
                                variant: "subtitle2",
                                color: "text.secondary",
                                noWrap: true
                            }}
                        />
                    </Box>
                </Box>
                <CustomBox>
                    {productOffers && productOffers.map((offer, index) => (
                        <ProductOfferCard
                            key={index}
                            offer={offer}
                            isBestOffer={bestOfferId == offer.id}
                        />
                    ))}
                </CustomBox>
            </ScrollableContent>
        </>
    );
}

const ScrollableContent = styled(Box)({
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
});

const CustomBox = styled(Box)(({ theme }) => ({
    display: 'grid',
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.25),
    gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
    margin: '0 auto', // Center the grid container
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.25), // Uniform padding on all sides
        margin: '0 auto', // Center the grid container on small screens
    },
}));

import { Box, Typography, Tooltip, LinearProgress, Divider } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info'; // Info icon for price indication
import useSystemNotification from "../../api/signalr/useSystemNotification";
import { useEffect, useRef, useState } from "react";



export function ProductDetailsStatus() {
    const statusMessage = useSystemNotification();
    const [progress, setProgress] = useState(0);
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        switch (statusMessage) {
            case "FilteringByPhotoStarted":
                setIsVisible(true);
                if (timerRef.current !== null) {
                    clearInterval(timerRef.current);
                }
                timerRef.current = setInterval(() => {
                    setProgress((prevProgress) => {
                        if (prevProgress < 100) {
                            return prevProgress + 6;
                        }
                        return prevProgress;
                    });
                }, 1000);
                break;
            case "FilteringByPhotoFinished":
                if (timerRef.current !== null) {
                    clearInterval(timerRef.current);
                }
                setProgress(100);
                break;
            case "FilteringByLLMStarted":
                setProgress(0);
                if (timerRef.current !== null) {
                    clearInterval(timerRef.current);
                }
                timerRef.current = setInterval(() => {
                    setProgress((prevProgress) => {
                        if (prevProgress < 100) {
                            return prevProgress + 20;
                        }
                        return prevProgress;
                    });
                }, 1000);
                break;
            case "FilteringByLLMFinished":
                if (timerRef.current !== null) {
                    clearInterval(timerRef.current);
                }
                setProgress(100);
                setIsVisible(false);
                break;
        }
    }, [statusMessage]);

    const getStatusText = () => {
        switch (statusMessage) {
            case 'FilteringByPhotoStarted':
                return 'Filtering by photo...';
            case 'FilteringByLLMStarted':
                return 'Filtering by LLM...';
            default:
                return 'Idle';
        }
    };



    return (
        isVisible ? <Box sx={{ width: '100%', my: 2 }} >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography variant="body2" sx={{ mr: 1 }}>{getStatusText()}</Typography>
                <Tooltip title="Showing progress of filtering and refreshing operations">
                    <InfoIcon fontSize="small" color="action" />
                </Tooltip>
            </Box>
            <LinearProgress variant="determinate" value={progress} />
        </Box > : <Divider sx={{ my: 1 }} />
    )
}